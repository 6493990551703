import * as React from 'react';
import Box from '@material-ui/core/Box';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function ChatDrawer() {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
          <Drawer
            sx={{
              width: '20%',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '20%',
                minWidth: 400,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >

            <Divider />
            <Box sx={{display: 'flex', justifyContent:'flex-end',flexDirection:'column'}}>h</Box>
          
          </Drawer>
      
      );
}